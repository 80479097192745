@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@font-face {
  font-family: 'Virgil';
  src: local('Virgil'), url(virgil.woff) format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
}

.App {
  position: relative;
  padding-top: 43px; /* Adjust this value to match the height of your navigation bar */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  background-image: paint(rough-painter);
  --rough-border-width: 3;
  --rough-fill: red;
  --rough-fill-weight: 9;
  --rough-fill-style: zigzag;
}

input {
  background-color: #fff0;
  --rough-border-width: 3;
  --rough-fill: white;
  --rough-fill-style: solid;
  width: 100%;
  box-sizing: border-box;
  font-size: 15px;
  border: none;
  padding: 0px;
  font-family: Virgil;
  outline: none;
}

input[readOnly] {
  cursor: default;
}

.grid-item.selected {
  border-left: 2px solid #0000FF;
  border-right: 2px solid #0000FF;
  padding: 0px;
}

.grid-item.first-selected {
  border-top: 2px solid #0000FF;
}

.grid-item.last-selected {
  border-bottom: 2px solid #0000FF;
}

.month-container {
  font-family: 'Virgil', sans-serif;

  display: flex;
  position: relative;
  z-index: 1; 
}

.month-header {
  /* Your styles for the header */
  background-color: #EA9999; /* Example background color */
  text-align: center; /* Center the text */
  justify-content: center;
}

.calendar-entry {
  justify-content: left;
  width: 252px;
}

.grid-container {
  display: grid;
  grid-template-columns: 52px 1fr; /* One column taking full width */
  grid-template-rows: 1fr 1fr 1fr; /* Three rows of equal height */
  /* gap: 10px; */
  font-family: Virgil;
  background-image: paint(rough-painter);  

}

.grid-item {
  border-top: 1px solid #F3F3F3;
  border-left: 1px solid #F3F3F3;
  border-right: 1px solid #F3F3F3;
  box-sizing: border-box;
  padding: 5px; /* Padding inside each grid item */
  margin: 0;
  display: flex;
  align-items: center;
  /* background-image: paint(rough-painter);  */
  --rough-border-width: 1;
  --rough-fill: white;
  --rough-fill-style: solid;

}

.calendar-number {
  border-right: 0;
  justify-content: center;
}



.grid-item:last-child, .calendar-number:nth-last-child(-n+2) {
  border-bottom: 1px solid #F3F3F3;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

.navigation-bar {
  position: fixed; /* Position the navigation bar fixed on the page */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Make the navigation bar extend full width */
  z-index: 100; /* Ensure it's above other content */
  background-color: #fefefe; /* Light background color */
  padding: 10px 0; /* Vertical padding */
  text-align: left; /* Center-align the content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.title {
  font-family: 'Montserrat', sans-serif;
  margin: 0; /* Remove default margin */
  margin-left: 55px;
  color: #333; /* Dark text color */
  font-size: 20px; /* Larger font size */
}

.hamburger-icon img {
  width: 100%;
  height: auto;
}


.hamburger-icon {
  position: absolute;
  width: 30px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px; /* Adjust as needed */
  width: 30px; /* Adjust as needed */
}

.hamburger-icon div {
  background-color: #666; /* Line color */
  height: 3px; /* Line thickness */
  width: 100%;
}

.popover-overlay {
  z-index: 1000; /* You can increase this value if needed */
  width: 500px;
}

.popover {
  max-width: 335px !important;
}

.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 12px 16px; /* Adjust the padding to ensure adequate spacing */
  background: #ffffff; /* White background for better contrast */
  border-radius: 8px; /* Soften the corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 8px; /* Ensure some space above the user info container */
  border: 1px solid #ddd; /* Add a subtle border */
  width: 300px;
}

.user-email {
  margin-bottom: 16px; /* Ensure some space between the email and the button */
  font-size: 1rem;
  color: #333;
}

.logout-button {
  padding: 6px 12px; /* Optimize padding for the button */
  font-size: 1rem; /* Match the font size with the email for consistency */
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  border: none; /* Removing the border for a cleaner look */
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #0056b3;
}

.calendar-dropdown {
  margin-bottom: 20px;
}
